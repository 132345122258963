<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import moment from 'moment'
export default {
  name: 'sessionManage',
  data() {
    return {
      ...api.command.getState(),
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/farmRound/page',
      current: 1,
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input',
        },
        {
          label: '',
          type: 'text',
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'name',
          title: '名称',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div class="product-order-item-copy">
                <div>{text.length > 45 ? text.substring(0, 45) + '...' : text}</div>
              </div>
            )
          },
        },

        {
          dataIndex: 'startTime',
          title: '场次时间',
          align: 'left',
          customRender: function (text, records) {
            return records.startTime == null ? '' : <div>{records.startTime + '~' + records.endTime}</div>
          },
          onExport: (text, records) => {
            return `${records.startTime + '~' + records.endTime}`
          },
        },
        {
          dataIndex: 'peopleLimit',
          title: '人数限制',
          align: 'left',
          sorter: (a, b) => a.peopleLimit - b.peopleLimit,
        },
        {
          dataIndex: 'remark',
          title: '备注',
          align: 'left',
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                name: '编辑',
                onClick: () => this.$router.push(`/commodityManagement/sessionManageDetail?id=${records.id}`),
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.delPut.call(this, {
                    url: `/farmRound/deleteBatch`,
                    params: { idList: [records.id] },
                  })
                },
              },
            ]
          },
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/commodityManagement/sessionManageDetail'),
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.delPut.call(this, {
                  url: '/farmRound/deleteBatch',
                  params: { idList },
                })
              },
            },
          ],
        },
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
